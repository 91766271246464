import { PptLayoutEnum } from './enums';
import {
  buildKiGroupShortObjects,
  setupKiGroupShortSlide,
} from './layoyts/kiGroupShortLayout';
import { buildLongLayoutObjects, setupLongSlide } from './layoyts/longLayout';
import { PptLayout, PptSetupSlideProps } from './types';

const theme = { headFontFace: 'Montserrat', bodyFontFace: 'Montserrat' };

export const getPPTLayout = (layout: PptLayoutEnum): PptLayout => {
  const layouts = {
    longKiGroup: {
      masterSlideTitle: 'KI Group Long Profile',
      sectionTitle: 'KI Group Long Profile Section',
      theme,
      objects: buildLongLayoutObjects(layout),
      setupSlide: async (props: PptSetupSlideProps) => {
        await setupLongSlide(props);
      },
    },
    longKiProfessional: {
      masterSlideTitle: 'KI Professional Long Profile',
      sectionTitle: 'KI Professional Long Profile Section',
      theme,
      objects: buildLongLayoutObjects(layout),
      setupSlide: async (props: PptSetupSlideProps) => {
        await setupLongSlide(props);
      },
    },
    shortKiGroupOneProfilePerPage: {
      masterSlideTitle: 'One Profile per Page',
      sectionTitle: 'One Profile per Page Section',
      theme,
      objects: buildKiGroupShortObjects(layout),
      setupSlide: async (props: PptSetupSlideProps) => {
        await setupKiGroupShortSlide(props);
      },
    },
    shortKiGroupTwoProfilesPerPage: {
      masterSlideTitle: 'Two Profiles per Page',
      sectionTitle: 'Two Profiles per Page Section',
      theme,
      objects: buildKiGroupShortObjects(layout),
      setupSlide: async (props: PptSetupSlideProps) => {
        await setupKiGroupShortSlide(props);
      },
    },
  };

  const layoutSelected = layouts[layout];

  if (layoutSelected) {
    return layoutSelected;
  }

  throw new Error('Invalid PPT Layout');
};
