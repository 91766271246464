import {
  PlaceholderKeysEnum,
  PlaceholderKeysTypeMap,
  PptColorEnum,
  PptPlaceholderTypeEnum,
} from './enums';
import {
  FillPlaceholderProps,
  PlaceholderMethods,
  PptPlaceholderProps,
  PptSlideMasterPlaceholderObject,
} from './types';
import { camelCaseToTitleCase } from '~/shared/utils/camelCaseToTitleCase';

const getPlaceholderName = (key?: PlaceholderKeysEnum, id = 0): string =>
  `placeholder_${key ? key : ''}_id_${id}`;

const buildPlaceholderMethods = (
  key: PlaceholderKeysEnum,
  id?: number,
  type: PptPlaceholderTypeEnum = PptPlaceholderTypeEnum.BODY
): PlaceholderMethods => ({
  add: ({
    bold = false,
    bullet = false,
    color = PptColorEnum.BLACK,
    fontSize = 6,
    placeholderId,
    position,
  }: PptPlaceholderProps): PptSlideMasterPlaceholderObject => ({
    placeholder: {
      options: {
        ...position,
        align: 'left',
        bold,
        bullet,
        color,
        fontSize,
        name: getPlaceholderName(key, placeholderId ?? id),
        margin: 0,
        type,
      },
      text: camelCaseToTitleCase(key),
    },
  }),

  fill: ({
    slide,
    text = '',
    path = '',
    placeholderId,
    objectProps,
  }: FillPlaceholderProps) => {
    const props = {
      placeholder: getPlaceholderName(key, placeholderId ?? id),
      ...objectProps,
    };

    if (type === PptPlaceholderTypeEnum.BODY) {
      slide.addText(text, props);
    } else if (type === PptPlaceholderTypeEnum.IMAGE) {
      slide.addImage({ path, ...props });
    }
  },
});

export const getPlaceholders = (
  placeholderId?: number
): Record<PlaceholderKeysEnum, PlaceholderMethods> =>
  Object.values(PlaceholderKeysEnum).reduce(
    (placeholdersAccumulator, placeholderKey) => ({
      ...placeholdersAccumulator,
      [placeholderKey]: buildPlaceholderMethods(
        placeholderKey,
        placeholderId,
        PlaceholderKeysTypeMap[placeholderKey]
      ),
    }),
    {} as Record<PlaceholderKeysEnum, PlaceholderMethods>
  );
