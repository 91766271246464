import { PptLayoutEnum } from './ppt/enums';
import { PptExportButton } from './ppt/PptExportButton';
import pptExport from './pptExport';
import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import useProfileStore from '~/profile/stores/profile';
import { Employee } from '~/shared/models/api/employee';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PptExportModal: React.FC<ExportModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('profile');

  const { profile } = useProfileStore();

  if (!profile) {
    return null;
  }

  const handleClick = (layout: PptLayoutEnum, profiles: Employee[]) => {
    pptExport(layout, profiles);
    onClose();
  };

  const sections = [
    {
      titleKey: `export.ppt.layout.short.title`,
      buttons: [
        {
          imgSrc: '/ppt-icons/ki_group_short_one_per_page_layout_ppt_icon.png',
          onClick: () =>
            handleClick(PptLayoutEnum.SHORT_KI_GROUP_ONE_PROFILE_PER_PAGE, [
              profile,
            ]),
          text: t(`export.ppt.layout.short.one_per_page`),
        },
        {
          imgSrc: '/ppt-icons/ki_group_short_two_per_page_layout_ppt_icon.png',
          onClick: () =>
            handleClick(PptLayoutEnum.SHORT_KI_GROUP_TWO_PROFILES_PER_PAGE, [
              profile,
              profile,
            ]),
          text: t(`export.ppt.layout.short.two_per_page`),
        },
      ],
    },
    {
      titleKey: `export.ppt.layout.long.title`,
      buttons: [
        {
          imgSrc: '/ppt-icons/ki_group_long_layout_ppt_icon.png',
          onClick: () => handleClick(PptLayoutEnum.LONG_KI_GROUP, [profile]),
          text: t(`export.ppt.layout.long.ki_group`),
        },
        {
          imgSrc: '/ppt-icons/ki_professional_long_layout_ppt_icon.png',
          onClick: () =>
            handleClick(PptLayoutEnum.LONG_KI_PROFESSIONAL, [profile]),
          text: t(`export.ppt.layout.long.ki_professional`),
        },
      ],
    },
  ];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader data-testid="export-modal-header">
          {t(`export.ppt.title`)}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <VStack gap={2} ml={2}>
            {sections.map((section, sectionIndex) => (
              <Box
                bg="gray.50"
                key={`${section.titleKey}-${sectionIndex}`}
                p={4}
                rounded={8}
                shadow="sm"
              >
                {t(section.titleKey)}
                <HStack gap={2} mt={2} width="800px">
                  {section.buttons.map((button, buttonIndex) => (
                    <PptExportButton
                      key={`${section.titleKey}-${sectionIndex}-button-${buttonIndex}`}
                      {...button}
                    />
                  ))}
                </HStack>
              </Box>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PptExportModal;
