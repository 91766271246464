import { PptColorEnum, PptLayoutEnum } from '../enums';
import {
  addImage,
  addRectangle,
  addSquare,
  addText,
  buildEducation,
  buildExperiences,
  buildIndustries,
  buildJobTitle,
  buildLanguages,
  buildNameSurname,
  buildSkillsText,
} from '../helper';
import { getPlaceholders } from '../placeholders';
import { PptPositionProps, PptSetupSlideProps } from '../types';
import { loadAzureBlob } from '~/shared/api/loadAzureBlob';

const addTitle = (
  text: string,
  position: PptPositionProps,
  color: PptColorEnum
) => addText(text, { bold: true, fontSize: 12, position, color });

const {
  avatar,
  certificateLogo,
  certificateText,
  education,
  experience,
  industries,
  languages,
  nameSurname,
  jobTitle,
  skills,
} = getPlaceholders();

export const buildLongLayoutObjects = (layout: PptLayoutEnum) => {
  const isKiGroup = layout === PptLayoutEnum.LONG_KI_GROUP;

  const yellowOrBlue = isKiGroup ? PptColorEnum.YELLOW : PptColorEnum.BLUE;
  const yellowOrWhite = isKiGroup ? PptColorEnum.YELLOW : PptColorEnum.WHITE;
  const whiteOrBlue = isKiGroup ? PptColorEnum.WHITE : PptColorEnum.BLUE;
  const blackOrBlue = isKiGroup ? PptColorEnum.BLACK : PptColorEnum.BLUE;

  return [
    addRectangle({ x: 0, y: 0, w: 4.08, h: 7.5 }, PptColorEnum.BLACK),
    addSquare({ x: 3.82, y: 1.32, size: 0.18 }, yellowOrBlue),
    addSquare({ x: 3.3, y: 1.57, size: 0.38 }, yellowOrBlue),
    addSquare({ x: 3.68, y: 1.95, size: 0.64 }, yellowOrBlue),
    addSquare({ x: 3.87, y: 2.8, size: 0.34 }, yellowOrBlue),
    addSquare({ x: 3.43, y: 3.6, size: 0.32 }, yellowOrBlue),
    addSquare({ x: 3.78, y: 3.95, size: 0.18 }, yellowOrBlue),
    addSquare({ x: 3.1, y: 4.26, size: 0.64 }, yellowOrBlue),
    addSquare({ x: 3.87, y: 4.64, size: 0.46 }, yellowOrBlue),
    addSquare({ x: 3.68, y: 5.19, size: 0.64 }, yellowOrBlue),
    addSquare({ x: 3.48, y: 5.86, size: 0.18 }, yellowOrBlue),

    addText('Our Experts', {
      bold: true,
      color: yellowOrWhite,
      fontSize: 24,
      position: { x: 0.33, y: 0.24, w: 2.5, h: 0.44 },
    }),

    jobTitle.add({
      bold: true,
      color: PptColorEnum.WHITE,
      fontSize: 13,
      position: { x: 0.78, y: 1.22, w: 2.22, h: 0.22 },
    }),

    avatar.add({ position: { x: 0.83, y: 1.71, w: 1.5, h: 1.5 } }),

    nameSurname.add({
      bold: true,
      color: whiteOrBlue,
      fontSize: 13,
      position: { x: 0.78, y: 3.45, w: 1.33, h: 0.3 },
    }),

    addImage('/company-logos/KIgroup_Logo_White.png', {
      x: 0.33,
      y: 7,
      w: 0.93,
      h: 0.24,
    }),

    addTitle(
      'Professional Experience',
      { x: 4.49, y: 0.24, w: 4.35, h: 0.44 },
      blackOrBlue
    ),
    experience.add({
      fontSize: 8,
      position: { x: 4.59, y: 0.64, w: 4.35, h: 7 },
    }),

    addTitle('Expertise', { x: 9.3, y: 0.24, w: 1.73, h: 0.44 }, blackOrBlue),
    skills.add({
      bullet: true,
      fontSize: 8,
      position: { x: 9.4, y: 0.64, w: 1.73, h: 0.44 },
    }),

    addTitle('Education', { x: 11.31, y: 0.24, w: 1.87, h: 0.44 }, blackOrBlue),
    education.add({
      fontSize: 8,
      position: { x: 11.42, y: 0.64, w: 1.87, h: 1.8 },
    }),

    addTitle('Languages', { x: 11.31, y: 2.3, w: 1.87, h: 0.44 }, blackOrBlue),
    languages.add({
      fontSize: 8,
      position: { x: 11.42, y: 2.7, w: 1.87, h: 0.85 },
    }),

    addTitle(
      'Industries',
      { x: 11.31, y: 3.46, w: 1.87, h: 0.44 },
      blackOrBlue
    ),
    industries.add({
      bullet: true,
      fontSize: 8,
      position: { x: 11.42, y: 3.86, w: 1.87, h: 0.85 },
    }),

    addTitle(
      'Certifications',
      { x: 9.2, y: 5.15, w: 1.87, h: 0.44 },
      blackOrBlue
    ),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 0,
      position: { x: 9.3, y: 5.55, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 1,
      position: { x: 10.18, y: 5.55, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 2,
      position: { x: 11.06, y: 5.55, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 3,
      position: { x: 11.96, y: 5.55, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 4,
      position: { x: 9.3, y: 6.3, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 5,
      position: { x: 10.18, y: 6.3, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 6,
      position: { x: 11.06, y: 6.3, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 7,
      position: { x: 11.96, y: 6.3, w: 0.7, h: 0.7 },
    }),
    certificateText.add({
      bold: true,
      fontSize: 8,
      position: { x: 9.3, y: 7.05, w: 3.36, h: 0.48 },
    }),
  ];
};

export const setupLongSlide = async ({
  slide,
  profile,
}: PptSetupSlideProps) => {
  slide.addShape('roundRect', {
    x: 0.78,
    y: 1.66,
    w: 1.7,
    h: 1.7,
    rectRadius: 0.1,
    fill: { color: PptColorEnum.GRAY_LIGHT },
    lineSize: 1,
  });

  jobTitle.fill({ slide, text: buildJobTitle(profile) });

  nameSurname.fill({
    slide,
    text: buildNameSurname(profile),
  });

  skills.fill({
    slide,
    text: buildSkillsText(profile.main_skills),
  });

  education.fill({ slide, text: buildEducation(profile) });

  industries.fill({ slide, text: buildIndustries(profile) });

  languages.fill({ slide, text: buildLanguages(profile) });

  experience.fill({
    slide,
    text: buildExperiences(profile),
  });

  const avatarUrl = await loadAzureBlob(profile.image_url);

  avatar.fill({
    slide,
    path: avatarUrl,
    objectProps: { sizing: { type: 'cover', w: 1.6, h: 1.6 } },
  });

  const { certifications } = profile;

  if (certifications.length) {
    const certificationTexts: string[] = [];

    for (let i = 0; i < certifications.length; i++) {
      const { logo, name } = certifications[i].certificate;

      if (logo) {
        const path = await loadAzureBlob(logo);
        certificateLogo.fill({
          slide,
          placeholderId: i,
          path,
          objectProps: {
            sizing: { type: 'cover', w: 0.7, h: 0.7 },
          },
        });
      } else {
        certificationTexts.push(name);
      }
    }

    if (certificationTexts.length) {
      certificateText.fill({ slide, text: certificationTexts.join(', ') });
    }
  }
};
