import {
  FormControl,
  FormErrorMessage,
  Grid,
  useRadioGroup,
} from '@chakra-ui/react';
import CompanyCard from '~/profile/components/export/pdf/CompanyCard';
import { COMPANIES } from '~/shared/constants/companies';
import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';

interface CompaniesRadioProps {
  id: string;
  selected?: string;
  error: FieldError | undefined;
}

const CompaniesRadio: React.FC<CompaniesRadioProps> = ({
  id,
  selected,
  error,
}) => {
  const { setValue } = useFormContext();

  const handleChange = (companyId: string) => {
    const selected = COMPANIES.find(
      (item) => item.id === Number(companyId)
    )?.name;
    setValue(id, selected);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: id,
    onChange: handleChange,
  });

  return (
    <FormControl id={id} isInvalid={Boolean(error)} mb="2">
      <Grid
        data-testid="export-modal-companies"
        templateColumns="repeat(2, 1fr)"
        gap={4}
        {...getRootProps()}
      >
        {COMPANIES.map((company, index) => (
          <CompanyCard
            key={company.id}
            selected={selected}
            index={index}
            company={company}
            {...getRadioProps({ value: company.id, enterKeyHint: 'go' })}
          />
        ))}
      </Grid>
      <FormErrorMessage data-testid="test-form-error-message">
        {!!error && error.message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default CompaniesRadio;
