import { PptColorEnum, PptLayoutEnum } from '../enums';
import {
  addImage,
  addLine,
  addSquare,
  addText,
  buildDescription,
  buildJobTitle,
  buildNameSurname,
  buildRectangle,
  buildSkillsText,
} from '../helper';
import { getPlaceholders } from '../placeholders';
import { PptPositionProps, PptSetupSlideProps, PptShapeProps } from '../types';
import { loadAzureBlob } from '~/shared/api/loadAzureBlob';

export const addWhiteShadowedRectangle = (
  positionProps: PptPositionProps
): { rect: PptShapeProps } =>
  buildRectangle(positionProps, PptColorEnum.WHITE, {
    fill: { transparency: 0, type: 'solid' },
    shadow: {
      type: 'outer',
      color: PptColorEnum.BOX_SHADOW,
      opacity: 0.3,
      angle: 0,
      blur: 24,
      offset: 0,
    },
  });

const getKiShortProfileObject = (
  placeholderId?: number,
  rightMarginIncrement = 0
) => {
  const getX = (x: number) => rightMarginIncrement + x;
  const { avatar, description, nameSurname, jobTitle, skills } =
    getPlaceholders(placeholderId);

  return [
    addWhiteShadowedRectangle({ x: getX(0.72), y: 2.59, w: 3.61, h: 3.92 }),
    addWhiteShadowedRectangle({ x: getX(4.45), y: 2.59, w: 2.03, h: 3.92 }),
    addImage('/diamond-icon.svg', { x: getX(4.65), y: 3.15, w: 0.17, h: 0.15 }),
    addText('Insights/Expertise', {
      fontSize: 10,
      position: { x: getX(4.8), y: 3.15, w: 1.5, h: 0.17 },
    }),

    addImage('/company-logos/KIgroup_Logo_Black.png', {
      x: 0.84,
      y: 7.03,
      w: 0.93,
      h: 0.24,
    }),

    nameSurname.add({
      bold: true,
      fontSize: 17,
      position: { x: getX(0.8), y: 1.87, w: 3.52, h: 0.36 },
    }),
    jobTitle.add({
      bold: true,
      color: PptColorEnum.GRAY,
      fontSize: 11,

      position: { x: getX(0.8), y: 2.24, w: 3.52, h: 0.24 },
    }),
    description.add({
      fontSize: 9,
      position: { x: getX(0.8), y: 2.6, w: 3.52, h: 3.83 },
    }),
    avatar.add({ position: { x: getX(4.82), y: 1.8, w: 1.29, h: 1.29 } }),
    skills.add({
      bullet: true,
      fontSize: 9,
      position: { x: getX(4.5), y: 3.37, w: 2.15, h: 3.04 },
    }),
  ];
};

export const buildKiGroupShortObjects = (layout: PptLayoutEnum) => {
  if (layout === PptLayoutEnum.SHORT_KI_GROUP_TWO_PROFILES_PER_PAGE) {
    return [
      getPlaceholders().title.add({
        bold: true,
        fontSize: 24,
        position: { x: 0.72, y: 0.65, w: 11.85, h: 0.86 },
      }),
      ...getKiShortProfileObject(0, 0),
      addLine({ x: 6.75, y: 1.87, w: 0, h: 4.66 }),
      ...getKiShortProfileObject(1, 6.3),
    ];
  }
  const size = 0.59;
  const color = PptColorEnum.YELLOW;

  return [
    addSquare({ x: 12.74, y: 0, size }, color),
    addSquare({ x: 9.77, y: 0.59, size }, color),
    addSquare({ x: 12.15, y: 0.59, size }, color),
    addSquare({ x: 11.55, y: 1.19, size }, color),
    addSquare({ x: 12.74, y: 1.19, size }, color),
    addSquare({ x: 10.96, y: 2.77, size }, color),
    addSquare({ x: 10.36, y: 3.36, size }, color),
    addSquare({ x: 11.55, y: 4.51, size }, color),
    addSquare({ x: 12.15, y: 6.31, size }, color),
    addSquare({ x: 11.57, y: 6.91, size }, color),
    addSquare({ x: 12.74, y: 6.91, size }, color),

    getPlaceholders().title.add({
      position: { x: 0.33, y: 0.24, w: 2.16, h: 0.44 },
    }),
    ...getKiShortProfileObject(0, 0),
  ];
};

export const setupKiGroupShortSlide = async ({
  slide,
  profile,
  placeholderId,
}: PptSetupSlideProps) => {
  const { avatar, description, nameSurname, jobTitle, skills } =
    getPlaceholders(placeholderId);

  nameSurname.fill({ slide, text: buildNameSurname(profile) });

  jobTitle.fill({ slide, text: buildJobTitle(profile) });

  description.fill({ slide, text: buildDescription(profile) });

  skills.fill({ slide, text: buildSkillsText(profile.main_skills) });

  const avatarUrl = await loadAzureBlob(profile.image_url);

  avatar.fill({
    slide,
    path: avatarUrl,
    objectProps: {
      rounding: true,
      sizing: { type: 'cover', w: 1.29, h: 1.29 },
    },
  });
};
