import { Box, useDisclosure } from '@chakra-ui/react';
import { externalUrls } from '~/authentication/config';
import PdfExportModal from '~/profile/components/export/PdfExportModal';
import PptExportModal from '~/profile/components/export/PptExportModal';
import useProfileStore from '~/profile/stores/profile';
import { MainRoutesPaths } from '~/routes/enums/main';
import RoundedButton from '~/shared/components/buttons/RoundedButton';
import EmployeeAvatar from '~/shared/components/EmployeeAvatar';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import { Employee } from '~/shared/models/api/employee';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RiFilePdfFill,
  RiFilePpt2Fill,
  RiLogoutCircleRLine,
  RiSurveyLine,
  RiUserSettingsFill,
} from 'react-icons/ri';

interface UserMenuProps {
  loggedProfile: Employee | null;
  isLoading: boolean;
  handleLogoutClick: () => void;
}

const UserMenu: FC<UserMenuProps> = ({
  isLoading,
  handleLogoutClick,
  loggedProfile,
}) => {
  const { t } = useTranslation(['base', 'admin', 'profile']);
  const { isAdmin } = useAccessPermissions();
  const { profile } = useProfileStore();
  const { canExportProfile } = useAccessPermissions();
  const pdfModal = useDisclosure();
  const pptModal = useDisclosure();

  return (
    <Box>
      {canExportProfile && profile && (
        <>
          <PdfExportModal isOpen={pdfModal.isOpen} onClose={pdfModal.onClose} />
          <PptExportModal isOpen={pptModal.isOpen} onClose={pptModal.onClose} />
          <RoundedButton
            tooltipLabel={t(`profile:export.ppt.title`)}
            type="submit"
            onClick={pptModal.onOpen}
            isLoading={isLoading}
          >
            <RiFilePpt2Fill size={22} opacity={0.8} />
          </RoundedButton>
          <RoundedButton
            tooltipLabel={t(`profile:export.pdf`, { name: profile.first_name })}
            type="submit"
            onClick={pdfModal.onOpen}
            isLoading={isLoading}
          >
            <RiFilePdfFill size={22} opacity={0.8} />
          </RoundedButton>
        </>
      )}

      {externalUrls.feedbackSurvey && (
        <RoundedButton
          linkTo={externalUrls.feedbackSurvey}
          tooltipLabel={t(`base:feedback_survey`)}
          isLoading={isLoading}
        >
          <RiSurveyLine size={24} opacity={0.8} />
        </RoundedButton>
      )}
      {isAdmin && (
        <RoundedButton
          linkTo={MainRoutesPaths.ADMINISTER}
          tooltipLabel={t(`admin:admin`)}
          isLoading={isLoading}
        >
          <RiUserSettingsFill size={24} opacity={0.8} />
        </RoundedButton>
      )}

      <RoundedButton
        tooltipLabel={t(`profile:my_profile`)}
        linkTo={MainRoutesPaths.PROFILE}
        isLoading={isLoading}
      >
        <EmployeeAvatar imagePath={loggedProfile?.image_url} size="sm" />
      </RoundedButton>
      <RoundedButton
        onClick={handleLogoutClick}
        isLoading={isLoading}
        tooltipLabel={t(`base:logout`)}
      >
        <RiLogoutCircleRLine size={22} opacity={0.8} />
      </RoundedButton>
    </Box>
  );
};

export default UserMenu;
