import { PptLayoutEnum } from './ppt/enums';
import {
  addMasterSlide,
  addMetadata,
  addSection,
  addSlide,
} from './ppt/helper';
import { getPPTLayout } from './ppt/pptLayouts';
import { Employee } from '~/shared/models/api/employee';
import PptxGenJS from 'pptxgenjs';

const pptExport = async (
  layout: PptLayoutEnum,
  profiles: Employee[]
): Promise<void> => {
  if (!profiles.length) {
    return;
  }

  const pptx = new PptxGenJS();
  const pptTitle = `Profiles`;
  const layoutSelected = getPPTLayout(layout);

  addMetadata(pptx, layoutSelected, pptTitle);
  addMasterSlide(pptx, layoutSelected.masterSlideTitle, layoutSelected.objects);
  addSection(pptx, layoutSelected);

  const slide = addSlide(pptx, layoutSelected);

  for (let i = 0; i < profiles.length; i++) {
    await layoutSelected.setupSlide({
      placeholderId: i % 2 === 0 ? 0 : 1,
      profile: profiles[i],
      slide,
    });
  }

  pptx.writeFile({
    fileName: `${new Date().toISOString()}-${pptTitle}.pptx`,
  });
};

export default pptExport;
