export enum PptLayoutEnum {
  LONG_KI_GROUP = 'longKiGroup',
  LONG_KI_PROFESSIONAL = 'longKiProfessional',
  SHORT_KI_GROUP_ONE_PROFILE_PER_PAGE = 'shortKiGroupOneProfilePerPage',
  SHORT_KI_GROUP_TWO_PROFILES_PER_PAGE = 'shortKiGroupTwoProfilesPerPage',
}

export enum PlaceholderKeysEnum {
  AVATAR = 'avatar',
  CERTIFICATE_LOGO = 'certificateLogo',
  CERTIFICATE_TEXT = 'certificateText',
  EDUCATION = 'education',
  EXPERIENCE = 'experience',
  DESCRIPTION = 'description',
  INDUSTRIES = 'industries',
  JOB_TITLE = 'jobTitle',
  NAME_SURNAME = 'nameSurname',
  LANGUAGES = 'languages',
  SKILLS = 'skills',
  TITLE = 'title',
}

export enum PptPlaceholderTypeEnum {
  TITLE = 'title',
  BODY = 'body',
  IMAGE = 'pic',
}

export const PlaceholderKeysTypeMap: Record<
  PlaceholderKeysEnum,
  PptPlaceholderTypeEnum
> = {
  [PlaceholderKeysEnum.AVATAR]: PptPlaceholderTypeEnum.IMAGE,
  [PlaceholderKeysEnum.CERTIFICATE_LOGO]: PptPlaceholderTypeEnum.IMAGE,
  [PlaceholderKeysEnum.CERTIFICATE_TEXT]: PptPlaceholderTypeEnum.BODY,
  [PlaceholderKeysEnum.EDUCATION]: PptPlaceholderTypeEnum.BODY,
  [PlaceholderKeysEnum.EXPERIENCE]: PptPlaceholderTypeEnum.BODY,
  [PlaceholderKeysEnum.DESCRIPTION]: PptPlaceholderTypeEnum.BODY,
  [PlaceholderKeysEnum.INDUSTRIES]: PptPlaceholderTypeEnum.BODY,
  [PlaceholderKeysEnum.JOB_TITLE]: PptPlaceholderTypeEnum.BODY,
  [PlaceholderKeysEnum.NAME_SURNAME]: PptPlaceholderTypeEnum.BODY,
  [PlaceholderKeysEnum.LANGUAGES]: PptPlaceholderTypeEnum.BODY,
  [PlaceholderKeysEnum.SKILLS]: PptPlaceholderTypeEnum.BODY,
  [PlaceholderKeysEnum.TITLE]: PptPlaceholderTypeEnum.BODY,
};

export enum PptColorEnum {
  BLACK = '000000',
  BLUE = '197AEF',
  BOX_SHADOW = '9C9C9C',
  GRAY = '6D718B',
  GRAY_LIGHT = 'D1D1D1',
  WHITE = 'FFFFFF',
  YELLOW = 'EFF112',
}

export enum PptMetadataEnum {
  AUTHOR = 'SMS APP',
  COMPANY = 'KI Group',
  LAYOUT = 'LAYOUT_WIDE',
  SUBJECT = `Profile Presentation`,
}
