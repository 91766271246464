export enum LanguageProficiency {
  BASIC = 1,
  INTERMEDIATE = 2,
  PROFESSIONAL = 3,
  NATIVE_SPEAKER = 4,
}

export const LanguageProficiencyBackgroundColor: Record<
  LanguageProficiency,
  string
> = {
  [LanguageProficiency.BASIC]: 'gray.50',
  [LanguageProficiency.INTERMEDIATE]: 'gray.100',
  [LanguageProficiency.PROFESSIONAL]: 'gray.200',
  [LanguageProficiency.NATIVE_SPEAKER]: 'gray.300',
};

export const LanguageProficiencyBorderColor: Record<
  LanguageProficiency,
  string
> = {
  [LanguageProficiency.BASIC]: 'gray.200',
  [LanguageProficiency.INTERMEDIATE]: 'gray.300',
  [LanguageProficiency.PROFESSIONAL]: 'gray.300',
  [LanguageProficiency.NATIVE_SPEAKER]: 'gray.400',
};

export const LanguageProficiencyText: Record<LanguageProficiency, string> = {
  [LanguageProficiency.BASIC]: 'basic',
  [LanguageProficiency.INTERMEDIATE]: 'intermediate',
  [LanguageProficiency.PROFESSIONAL]: 'professional',
  [LanguageProficiency.NATIVE_SPEAKER]: 'native',
};
